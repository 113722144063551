.mu8-sidebar-hide-btn{
    position: absolute;
    right: -20px;
    top: 96px;
    width: 20px;
    height: 90px;
    background-color: #0293d6;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}

.mu8-sidebar-hide-btn-content{
    margin: 10px 0 0 4px;
}